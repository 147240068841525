<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems" dense
			:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header
			:loading="loading" loading-text="加载中...">
            <template v-slot:top>
				<div class="d-flex flex-wrap pl-2 pt-3 pb-1" style="gap:16px">
					<v-btn-toggle mandatory dense v-model="filter" @change="loadData" style="flex: 0 1 auto">
						<v-btn>全部</v-btn>
						<v-btn>待确认</v-btn>
						<v-btn>已付款</v-btn>
					</v-btn-toggle>
					<v-text-field placeholder="姓名/证件号/手机号" v-model.trim="psearch" dense clearable hide-details style="flex: 0 1 auto"
						append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field :value="pdates.join(' ~ ')" label="下单/付款日期" v-on="on" v-bind="attrs" readonly hide-details dense
								append-icon="mdi-close" @click:append="pdates=[]" style="flex: 0 1 auto;width:240px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdates" @change="menu1=false" range locale="zh-cn" scrollable></v-date-picker>
					</v-menu>
					<v-btn color="primary" @click.stop="exportXLS" class="ml-8">导出</v-btn>
				</div>
                <v-divider/>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click.stop="editItem(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:item.progress.order="{ item }">
                {{formatTime(item.progress.order)}}
            </template>
            <template v-slot:item.progress.updateAmount="{ item }">
                {{formatTime(item.progress.updateAmount)}}
            </template>
            <template v-slot:item.progress.payment="{ item }">
                {{formatTime(item.progress.payment)}}
            </template>
			<template v-slot:item.insurance="{ item }">
				{{getInsurance(item)}}
			</template>
            <template v-slot:item.idcard="{ item }">
                {{getMaskedIdcard(item)}}
            </template>
            <template v-slot:item.opts="{ item }">
                {{getOptsStr(item)}}
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent max-width="500">
			<v-card>
				<v-card-title>付款状态</v-card-title>
				<template v-if="payway==='微信'">
					<v-card-text>
						<v-form ref="paymentform" :readonly="locked">
							<v-container>
								<v-row>
									<v-col cols="12" sm="4"><v-text-field label="姓名" v-model="currentItem.pii.username" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="微信应付" v-model.number="amount" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="微信实付" v-model="actualPayment" readonly dense
										placeholder="未支付" persistent-placeholder/></v-col>
								</v-row>
								<v-text-field label="备注" v-model="paymentComment" dense />
							</v-container>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn @click="dialog=false">取消</v-btn>
						<v-btn color="primary" @click="saveWXPay" :loading="loadingsave" :disabled="loadingsave||locked">保存</v-btn>
					</v-card-actions>
				</template>
				<template v-else>
					<v-card-text>
						<v-form ref="paymentform" :readonly="locked">
							<v-container>
								<v-row>
									<v-col cols="12" sm="4"><v-text-field label="姓名" v-model="currentItem.pii.username" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="应付" v-model="amount" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="实付" v-model.number="actualPayment" type="number" :rules="numberRules"
										dense hide-spin-buttons placeholder="请输入实付金额" persistent-placeholder/></v-col>
								</v-row>
								<v-text-field label="备注" v-model="paymentComment" dense />
							</v-container>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn @click="dialog=false">取消</v-btn>
						<v-btn color="primary" @click.stop="save" :loading="loadingsave" :disabled="loadingsave||locked">保存</v-btn>
					</v-card-actions>
				</template>
			</v-card>
        </v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime, formatDate} from '../utils'

    export default {
        name: 'Payment',
        data() {
            return {
                authed: false,
				supv: false,
                headers: [
                    {text:'', value:'actions', width:40, sortable: false},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'付款方式', value:'payment.way', width:100},
                    {text:'下单时间', value:'progress.order', width:180},
                    {text:'付款确认时间', value:'progress.payment', width:180},
                    {text:'原价', value:'price', width:100},
                    {text:'应付', value:'payment.amount', width:100},
                    {text:'实付', value:'payment.actual', width:100},
                    {text:'付款备注', value:'payment.comment', width:200},
                    {text:'代理人', value:'consultantName', width:100},
                    {text:'套餐内容', value:'opts', sortable: false, width:180},
                    {text:'已选专家', value:'doctorName', width:100},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'电话', value:'pii.phone', width:120},
                    {text:'订单号', value:'_id', width:100},
                    {text:'订单类型', value:'orderType', width:120},
                ],
                items: [],
                loading: false,
                dialog: false,
                loadingsave: false,
				locked: false,
                currentItem: {pii:{}},
				numberRules: [v => (/^\d+(\.\d+)?$/g).test(v) || '数字格式无效'],
				payway:'',
				amount: '',
				actualPayment: '',
				paymentComment: '',
				filter: 0,
				options: {},
				totalItems: 0,
				psearch: '',
				pdates: [],
				menu1: false,
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			pdates(ds) {
				if (ds.length === 1) return;
				if (ds.length === 2) {
					if (new Date(ds[0]) > new Date(ds[1])) {
						[ds[0], ds[1]] = [ds[1], ds[0]];
					}
				}
				this.loadData();
			},
		},
		created() {
            this.formatTime = formatTime;
		},
        mounted() {
			this.supv = this.$hasPrivilege('付款管理');
			this.authed = this.supv || this.$hasPrivilege('代理人');
			if (!this.authed) return;
		},
        methods: {
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
            async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const _ = db.command;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
            },
			getFilter() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{payment:_.exists(true)}];
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					const field = this.filter == 2 ? 'progress.payment' : 'progress.order';
					filter.push({[field]:_.gt(d1).lt(d2)});
				}
				if (this.$hasPrivilege('付款管理')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('代理人')) {
					const u = this.$getCurrentUser();
					filter.push({consultantUsername: u.username});
				}
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				filter.push({hide:_.neq(true)});
				const filters = [
					[],
					[{"payment.actual": _.exists(false)}],
					[{"payment.actual": _.exists(true)}],
				];
				const f = filter.concat(filters[this.filter]);
				return _.and(f);
			},
			async exportXLS() {
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2order').where(filter).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(filter).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const header = [[
					'订单号',
					'姓名',
					'性别',
					'证件类型',
					'证件号码',
					'电话',
					'检查项目',
					'所选专家',
					'代理人',
					'原价',
					'应付',
					'实付',
					'支付方式',
					'付款备注',
					'下单时间',
					'付款确认时间',
				]];
				const data = res.data.map(x => [
					x._id,
					x.pii.username,
					x.pii.gender,
					x.pii.idtype,
					x.pii.idcard,
					x.pii.phone,
					this.getOptsStr(x),
					x.doctorName || '',
					x.consultantName || '',
					x.price || '',
					x.payment.amount || '',
					x.payment.actual || '',
					x.payment.way || '',
					x.payment.comment || '',
					formatTime(x.progress.order),
					formatTime(x.progress.payment),
				]);
				const t = (this.pdate1 && this.pdate2) ? this.pdate1 + '~' + this.pdate2 : '付款';
				const filename = "付款.xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data), {dateNF: 'YYYY-MM-DD HH:mm:ss'});
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
				//return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
			},
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
            editItem(item) {
				if (item.payment.actual !== undefined && item.payment.actual !== null) {
					this.$dialog.message.error('此订单已付款');
					return;
				}
                this.currentItem = item;
				this.payway = item.payment.way;
				this.amount = item.payment.amount;
				this.actualPayment = item.payment.actual;
				this.paymentComment = item.payment.comment;
				this.locked = item.payment.actual !== undefined && item.payment.actual !== null;
                this.dialog = true;
            },
            async save() {
				if (!this.$refs.paymentform.validate()) return;
				this.loadingsave = true;
				try {
					const id = this.currentItem._id;
					await this.$callCloudFunc({
						funcname:'savePayment',
						data: {
							id,
							actual: parseFloat(this.actualPayment),
							comment: this.paymentComment || '',
						}
					});
					this.$dialog.message.success('已确认付款');
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					this.$dialog.message.error('确认付款失败');
					console.error(err);
				}
				this.loadingsave = false;
			},
            async saveWXPay() {
				this.loadingsave = true;
				try {
					const id = this.currentItem._id;
					await this.$callCloudFunc({
						funcname:'updatePayment',
						data: {
							id,
							comment: this.paymentComment || '',
						}
					});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					this.$dialog.message.error('保存失败');
					console.error(err);
				}
				this.loadingsave = false;
			},
        }
    }
</script>